import Rest from '../Rest'

export default class PracticalTestService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/practical_tests'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  addParticipants(practicalTestId, data) {
    return this.post(`${practicalTestId}/students`, data).then(
      (data) => {
        return data
      }
    )
  }
  removeParticipant(practicalTestId: Number, participantsIds) {
    return this.put(`${practicalTestId}/students/remove`,{
      participants: participantsIds
    })
  }

  // Teachers
  addTeachers(practicalTestId, data) {
    return this.post(`${practicalTestId}/teachers`, data).then(
      (data) => {
        return data
      }
    )
  }
  removeTeacher(practicalTestId: Number, participantsIds) {
    return this.put(`${practicalTestId}/teachers/remove`,{
      participants: participantsIds
    })
  }
}
