<template>
  <div class="p-6">
     <vx-card>
        <div class="vx-row mt-2">
          <div class="vx-col sm:w-1/1 w-full mb-2">
            <b>Prova Prática</b>
          </div>
          <div class="vx-col sm:w-1/1 w-full mb-2" v-if="pacticalTest">
            {{ pacticalTest.questionnaire_name }}
          </div>
        </div>

        <div class="vx-row mt-2" v-if="!isFullscreen">
          <div class="vx-col sm:w-1/1 w-full mb-2">
            <vs-input
              id="student_name"
              class="w-full"
              :label="$t('practical_test.dashboard.group')"
              v-model="group"/>
          </div>
        </div>

        <div class="vx-row mt-2" v-if="!isFullscreen">
          <div class="vx-col w-full flex justify-end mb-2">
            <vs-button
              color="primary"
              icon="search"
              :title="$t('buscar')"
              class="filter-button"
              @click="filter()">{{ $t('filtrar') }}</vs-button>
            <vs-button
                class="ml-3"
                type="border"
                @click="goBack">
                {{ $t('common.back') }}
              </vs-button>
              <vs-button
                class="ml-3"
                icon="monitor"
                type="border"
                @click="modeScreenfull">
                  Modo quiosque
              </vs-button>
          </div>
        </div>

        <vs-divider class="mt-4" v-if="praticalTestStudents.length > 0">Alunos</vs-divider>

        <div class="vx-row mt-2">
          <div class="vx-col w-full">

            <vs-table :data="praticalTestStudents" :sst="true" current-sort-type="asc"
              :no-data-text="noDataText">
              <template slot="thead">
                <vs-th width="10%">Nome</vs-th>
                <vs-th width="15%">Email</vs-th>
                <vs-th width="5%">Matrícula</vs-th>
                <vs-th width="70%">
                  <div class="grid grid-cols-3 justify-items-center flex w-full">
                    <div class="flex justify-center">
                      <vs-chip transparent color="success" class="text-base">Não iniciada</vs-chip>
                    </div>
                    <div class="flex justify-center">
                      <vs-chip transparent color="warning" class="text-base">Iniciada</vs-chip>
                    </div>
                    <div class="flex justify-center">
                      <vs-chip transparent color="primary" class="text-base">Finalizada</vs-chip>
                    </div>
                  </div>
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr
                    :key="i"
                    v-for="(tr, i) in data">

                  <vs-td class="break-all p-1 pl-4">
                    {{ tr.data.name }}
                  </vs-td>
                  <vs-td class="break-all p-1 pl-4">
                    {{ tr.data.email }}
                  </vs-td>
                  <vs-td class="break-all p-1 pl-4">
                    {{ tr.data.identifier }}
                  </vs-td>

                  <vs-td>
                    <vs-table :data="sections">
                      <template slot="thead">
                        <vs-th width="50%">Sessão</vs-th>
                        <vs-th width="10%">Início</vs-th>
                        <vs-th width="10%">Qtd. Questões</vs-th>
                        <vs-th width="10%">Qtd. Respondidas</vs-th>
                      </template>

                      <template slot-scope="{data}">
                        <vs-tr
                          :key="j"
                          v-for="(trSection, j) in data"
                          :class="classSection(trSection, tr.data)"
                          style="border-bottom: thin solid;">

                          <vs-td>
                            {{ trSection.data.title }}
                          </vs-td>

                          <vs-td>
                            {{ getSectionStartAt(trSection, tr.data) }}
                          </vs-td>

                          <vs-td>
                            {{ getSectionQtyQuestion(trSection, tr.data) }}
                          </vs-td>

                          <vs-td>
                            {{ getSectionQtyAnswered(trSection, tr.data) }}
                          </vs-td>

                        </vs-tr>
                      </template>
                    </vs-table>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
    </vx-card>
  </div>
</template>

<script>

import screenfull from 'screenfull'

import PracticalTestStudentService from '@/services/api/PracticalTestStudentService'
import PracticalTestService from '@/services/api/PracticalTestService'

export default {
  props: {
    id: {
      default: null,
      type: Number
    }
  },
  data: () => ({
    group: '',
    pacticalTest: null,
    praticalTestStudents: [],
    sections: [],
    interval: null,
    isFullscreen: false
  }),
  computed: {
    noDataText(){
     return this.$t('nenhum-registro-encontrado')
    }
  },
  methods: {
    addNew() {
      this.$router.push('/practical_tests/create')
    },
     goBack() {
      this.$router.push('/practical_tests/dashboard')
    },
    filter() {
      this.$vs.loading()
      this.service.get('', {
        'group': this.group,
        'practical_test_id': this.id
      }).then(response => {
        this.praticalTestStudents = response.result
        this.sections = response.sections
      },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    sections(data) {
      const analytic = JSON.parse(data.analytic)
      return analytic.sections
    },
    getSectionName(id) {
      const section = this.getSection(id)
      if (section && section.data) {
        return section.data.title
      }
      return ''
    },
    getSectionQtyQuestion(section, data) {
      const s = this.getSectionAnalytic(data, section)
      if (s) {
        return s.qty_question
      }
      return ''
    },
    getSectionStartAt(section, data) {
      const s = this.getSectionAnalytic(data, section)
      if (s && s.started_at) {
        return this.$utils.format.dateWithFormat(s.started_at, 'DD/MM/YYYY - kk:mm:ss')
      }
      return '-'
    },
    getSectionQtyAnswered(section, data) {
      const s = this.getSectionAnalytic(data, section)
      if (s) {
        return s.answered_questions ? s.answered_questions.length : 0
      }
      return 0
    },
    getSectionAnalytic(data, section) {
      const analytic = JSON.parse(data.analytic)
      if (!analytic) return null
      const s = analytic.sections.filter(item => {
        return item.id === section.id
      }).shift()
      return s
    },
    classSection(section, data) {
      const s = this.getSectionAnalytic(data, section)
      //console.log('s...', s)
      // return s !== null && s.started_at !== null
      if (s !== null) {
        if (s.started_at !== null && s.answered_questions.length === s.qty_question) {
          return 'section-finished'
        } else if (s.started_at !== null && s.answered_questions.length !== s.qty_question) {
          return 'section-started'
        } else {
          return 'section-not-started'
        }
      }
      return 'section-not-started'
    },
    modeScreenfull(){
      screenfull.request(document.documentElement, {
          navigationUI: 'hide'
        })
      screenfull.on('change', () => {
        this.isFullscreen = screenfull.isFullscreen
        //console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No');
      })
    },
    modeScreenfullExit(){
      screenfull.exit()
    }
  },
  mounted() {
    this.practicalTestService.read({id: this.id}).then(data => {
      this.pacticalTest = data
    })

    this.interval = setInterval(() => {
      if (this.praticalTestStudents.length > 0) {
        this.filter()
      }
    }, 15000)
  },
  beforeMount() {
    this.service = PracticalTestStudentService.build(this.$vs)
    this.practicalTestService = PracticalTestService.build(this.$vs)
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style lang="scss">
  .section-not-started {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1);
  }

  .section-started {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1);
  }

  .section-finished {
    background: rgba(var(--vs-primary),.15);
    color: rgba(var(--vs-primary),1);
  }
</style>
