import Rest from '../Rest'

export default class PracticalTestStudentService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/practical_test_students'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }
}
